import { always } from 'ramda'
import { createReducer } from './../../utilities/miscellaneous'

// INITIAL_STATE
export const INITIAL_STATE = {
  scriptImported: false,
  trinityScriptImported: false,
}

// action types
export const SCRIPT_IMPORTED = 'adnimation/SCRIPT_IMPORTED'
export const INTERSTITIAL_AD_LOADED = 'adnimation/INTERSTITIAL_AD_LOADED'
export const STICKY_FOOTER_REFRESHED = 'adnimation/STICKY_FOOTER_REFRESHED'
export const CREATE_AD = 'adnimation/CREATE_AD'
export const DESTROY_AD = 'adnimation/DESTROY_AD'
export const AD_CREATED = 'adnimation/AD_CREATED'
export const AD_DESTROYED = 'adnimation/AD_DESTROYED'

// scriptImported :: () -> Action
export const scriptImported = always({ type: SCRIPT_IMPORTED })

// interstitialAdLoaded :: () -> Action
export const interstitialAdLoaded = always({ type: INTERSTITIAL_AD_LOADED })

// stickyFooterRefreshed :: () -> Action
export const stickyFooterRefreshed = always({ type: STICKY_FOOTER_REFRESHED })

// createAd :: String -> Action
export const createAd = id => ({
  type: CREATE_AD,
  id,
})

// destroyAd :: String -> Action
export const destroyAd = id => ({
  type: DESTROY_AD,
  id,
})

// adCreated :: String -> Action
export const adCreated = id => ({
  type: AD_CREATED,
  id,
})

// adDestroyed :: String -> Action
export const adDestroyed = id => ({
  type: AD_DESTROYED,
  id,
})

export default createReducer(INITIAL_STATE, {
  [SCRIPT_IMPORTED]: state => ({
    ...state,
    scriptImported: true,
  }),
})
